<template>
  <div>
    <CSBreadcrumb />
    <div class="report-info">
      <div class="report-info-item">
        <span class="report-info-item-label">报告名称</span>
        <span>{{reportDetail.name}}</span>
      </div>
      <div class="report-info-item">
        <span class="report-info-item-label">检查日期</span>
        <span>{{reportDetail.startTime}} 至 {{reportDetail.endTime}}</span>
      </div>
      <div class="report-info-item">
        <span class="report-info-item-label">检查结果</span>
        <span>{{reportDetail.isQualified ? '合格' : '不合格'}}</span>
      </div>
      <div class="report-info-item">
        <span class="report-info-item-label">检查人</span>
        <span>{{reportDetail.createUserName}}</span>
      </div>
    </div>

    <template v-for="(department, departmentIndex) in reportDetail.details">
<!--      <transition :key="`${department.id}_${departmentIndex}_transition`">-->
        <div class="department-report" :key="`${department.id}_${departmentIndex}_brief`"
          v-show="!spreadState[department.id]" @click="changeSpreadState(department, departmentIndex)"
        >
          <span>受检部门：{{department.department}}</span>
          <span>部门主管：{{department.leaderName}}</span>
          <div class="allow-click">
            <template v-if="type === 1">
              填写陈述
            </template>
            <template v-else>
            <span :class="['cs-tag', department.isRectify ? 'warning' : 'success']"
                  style="font-size: 20px;padding: 6px 20px;margin-right: 15px;border-radius: 20px;"
            ><template v-if="department.isRectify">
                需要整改 {{reportState[department.state] !== undefined ? `【${reportState[department.state]}】` : ''}}
              </template><template v-else>无需整改</template>
            </span>查看</template>
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-menuxiala"></use>
            </svg>
          </div>
        </div>
        <div class="department-report-detail" :key="`${department.id}_${departmentIndex}_detail`"
          v-show="spreadState[department.id]"
        >
          <div class="department-report-item">
            <div class="department-report-item-label">
              受检部门
            </div>
            <div class="department-report-item-content">
              {{department.department}}
            </div>
          </div>
          <div class="department-report-item">
            <div class="department-report-item-label">
              部门主管
            </div>
            <div class="department-report-item-content">
              {{department.leaderName}}
            </div>
          </div>
          <div class="department-report-item divider">
            <div class="department-report-item-label top">
              考核汇总
            </div>
            <div class="department-report-item-content">
              <CSTabBar :tabbar="assessmentTabBar" :checked-tab="checkedTab" @changeTabBar="index => changeTabBar(index, [department.dutyType])"/>
              <template v-if="checkedTab !== 2">
                <div>
                  总扣分项：{{department.adminQueryItemLog[checkedTab].itemCount}}项
                </div>
                <div style="margin-top: 10px;">
                  总扣分：{{department.adminQueryItemLog[checkedTab].scoreCount}}分
                </div>
              </template>
              <div style="max-height: 300px; overflow-y: auto;padding:0 2px;margin-top: 15px;">
                <table
                    border="1"
                    v-if="checkedTab === 1"
                    class="footable table table-stripped toggle-arrow-tiny modal-inner-table"
                >
                  <thead>
                  <tr class="head_row">
                    <th style="min-width: 230px">扣分时间</th>
                    <th>部门</th>
                    <th>扣分项</th>
                    <th>扣分</th>
                  </tr>
                  </thead>
                  <tbody>
                  <template v-if="department.adminQueryItemLog[checkedTab].logs && department.adminQueryItemLog[checkedTab].logs.length > 0">
                    <tr v-for="log in department.adminQueryItemLog[checkedTab].logs"
                        :key="log.id">
                      <td>{{log.createTime || '-'}}</td>
                      <td>{{departmentTypes[log.dutyType] || '-'}}</td>
                      <td>{{log.itemName || '-'}}</td>
                      <td>{{log.score}}</td>
                    </tr>
                  </template>
                  </tbody>
                </table>

                <table
                    border="1"
                    v-if="checkedTab === 2"
                    class="footable table table-stripped toggle-arrow-tiny modal-inner-table"
                >
                  <thead>
                  <tr class="head_row">
                    <th style="min-width: 230px">提交时间</th>
                    <th>部门</th>
                    <th>得分</th>
                  </tr>
                  </thead>
                  <tbody>
                  <template v-if="department.adminQueryItemLog[checkedTab].logs && department.adminQueryItemLog[checkedTab].logs.length > 0">
                    <tr v-for="log in department.adminQueryItemLog[checkedTab].logs"
                        :key="log.id">
                      <td>{{log.createTime || '-'}}</td>
                      <td>{{departmentTypes[log.dutyType] || '-'}}</td>
                      <td>
                        <span class="allow-click" @click="lookAssessmentItem(log)">{{log.score}}/{{log.scoreCount}}</span>
                      </td>
                    </tr>
                  </template>
                  </tbody>
                </table>

                <table
                    border="1"
                    v-if="checkedTab === 3"
                    class="footable table table-stripped toggle-arrow-tiny modal-inner-table"
                >
                  <thead>
                  <tr class="head_row">
                    <th style="min-width: 230px">扣分时间</th>
                    <th>部门</th>
                    <th>扣分项</th>
                    <th>扣分</th>
                    <th style="min-width: 180px;">相关巡检/工单</th>
                  </tr>
                  </thead>
                  <tbody>
                  <template v-if="department.adminQueryItemLog[checkedTab].logs && department.adminQueryItemLog[checkedTab].logs.length > 0">
                    <tr v-for="log in department.adminQueryItemLog[checkedTab].logs"
                        :key="log.id">
                      <td>{{log.createTime || '-'}}</td>
                      <td>{{departmentTypes[log.dutyType] || '-'}}</td>
                      <td>{{log.itemName || '-'}}</td>
                      <td>{{log.score}}</td>
                      <td>
                        <span class="allow-click" @click="lookSystemAssessmentTask(log)">查看</span>
                      </td>
                    </tr>
                  </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="department-report-item divider">
            <div class="department-report-item-label top">
              报告陈述
            </div>
            <div class="department-report-item-content" style="position: relative; z-index: 0;">
              <template v-if="type === 1">
                <WangEditor :editor-name="`editor_${department.id}_${departmentIndex}`" :parentComponent="`qualityReportDetail${department.id}_editor_${departmentIndex}`" />
              </template>
              <div v-else v-html="department.statement" style="max-height: 200px; overflow-y: auto;margin-top: -5px;"></div>
            </div>
          </div>

          <div class="department-report-item" v-if="type === 2">
            <div class="department-report-item-label top">
              处理过程
            </div>
            <div class="department-report-item-content">
              <table
                  border="1"
                  class="footable table table-stripped toggle-arrow-tiny modal-inner-table"
              >
                <thead>
                <tr class="head_row">
                  <th>操作时间</th>
                  <th>事件</th>
                  <th>详情</th>
                </tr>
                </thead>
                <tbody>
                <template v-if="department.logs && department.logs.length > 0">
                  <tr v-for="log in department.logs"
                      :key="log.id">
                    <td>{{log.createTime || '-'}}</td>
                    <td>
                      {{reportDealEvent[log.state] || '-'}}
                      <span class="cs-tag error" v-if="log.state === 4">已超时完成</span>
                    </td>
                    <td>
                      <span class="allow-click" @click="lookReportDealDetail(log, department)">查看</span>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
          </div>

          <div class="department-report-item">
            <div class="department-report-item-label">
              是否整改
            </div>
            <div class="department-report-item-content">
              <div v-if="type === 1">
                <CSRadio v-model="editDepartmentReport[department.id+'_'+departmentIndex].isRectify" :items="{1: '需要整改', 0: '无需整改'}"/>
              </div>
              <template v-else>
                {{department.isRectify ? '需要整改' : '无需整改'}}
              </template>
            </div>
          </div>
          <div style="font-size: 24px;text-align: center;margin: 0 auto;">
            <span class="allow-click" @click.stop="changeSpreadState(department, departmentIndex)">
              收起
              <svg class="icon reverse" aria-hidden="true">
                <use xlink:href="#icon-menuxiala"></use>
              </svg>
            </span>
          </div>
      </div>
<!--      </transition>-->
    </template>

    <div class="btn-group-wrap" v-if="type === 1">
      <button class="btn btn-primary" @click="saveReport(0)">保存</button>
      <button class="btn btn-primary" @click="saveReport(1)">提交</button>
    </div>
  </div>
</template>

<script>
import {
  adminQueryItemLogUrl,
  adminQueryReportDetailUrl,
  editorQualityReportUrl,
  queryInspectionUrl,
  queryTicketUrl
} from '@/requestUrl';
import CSBreadcrumb from "@/components/common/CSBreadcrumb";
import CSRadio from "@/components/common/CSRadio";
import WangEditor from "@/components/WangEditor";
import CSTabBar from "@/components/common/CSTabBar";
import {DEPARTMENT_TYPES} from "@/constant";

export default {
  name: "QualityReportReview",
  props: {
    type: Number,
    id: Number,
  },
  components: {
    CSBreadcrumb,
    CSRadio,
    WangEditor,
    CSTabBar,
  },
  data() {
    return {
      reportDealEvent: {
        1: '已说明',
        2: '已计划',
        3: '已整改',
        4: '已整改',
        5: '已验收',
      },
      reportDetail: {},
      reportState: {
        1: '已说明',
        2: '已计划',
        3: '已整改',
        4: '已验收'
      },
      spreadState: {},
      editDepartmentReport: {},
      assessmentTabBar: {
        1: '业务考核',
        2: '通用考核',
        3: '系统自动考核'
      },
      checkedTab: 1,
      assessmentItemLogData: {},
      departmentTypes: {
        [DEPARTMENT_TYPES.DIRECTOR]: "总监办公室",
        [DEPARTMENT_TYPES.MANAGER]: "经理办公室",
        [DEPARTMENT_TYPES.ENGINEERING]: "工程部",
        [DEPARTMENT_TYPES.CUSTOMER_SERVICE]: "客服部",
        [DEPARTMENT_TYPES.PROCTER_GAMBLE]: "保洁部",
        [DEPARTMENT_TYPES.SECURITY]: "保安部",
        [DEPARTMENT_TYPES.PERSONNEL]: "人事部",
        [DEPARTMENT_TYPES.FINANCIAL]: "财务部",
        [DEPARTMENT_TYPES.OPERATIONS]: "运营部",
        [DEPARTMENT_TYPES.INVESTMENT_PROMOTION]: "招商部",
        [DEPARTMENT_TYPES.QUALITY]: "品质管理部",
      },
      detailsIndex:null
    }
  },
  async mounted() {
    await this.getReportDetail();
  },
  methods: {
    /**
     * 查看报告详情
     * @param {Object} log 处理记录
     * @param {Object} departmentReport 部门报告
     * */
    lookReportDealDetail(log, departmentReport) {
      let messageHtml = '';
      switch (log.state) {
        case 1:
          messageHtml = `
             <div class="comment-panel">
               <div class="item">
                  <div class="item-label">原因说明</div>
                  <div>${departmentReport.cause}</div>
               </div>
             </div>
          `;
          break;
        case 2:
          messageHtml = `
             <div class="comment-panel">
               <div class="item" style="--item-label: 193px">
                  <div class="item-label">计划说明</div>
                  <div>${departmentReport.rectifyPlan}</div>
               </div>
               <div class="item" style="--item-label: 193px">
                  <div class="item-label">预计整改完成时间</div>
                  <div>${departmentReport.expectRectifyFinishTime}</div>
               </div>
             </div>
          `;
          break;
        case 3:
        case 4:
          messageHtml = `
             <div class="comment-panel">
               <div class="item">
                  <div class="item-label">整改结果</div>
                  <div>${departmentReport.rectifyResult}</div>
               </div>
             </div>
          `;
          break;
        case 5:
          messageHtml = `
             <div class="comment-panel">
               <div class="item">
                  <div class="item-label">验收结果</div>
                  <div>${departmentReport.acceptanceResult}</div>
               </div>
             </div>
          `;
          break;
      }
      this.$CSDialog.alert({
        title: '处理详情',
        messageHtml,
      })
    },
    /**
     * 查看考核项
     * @param {Array} log 考核项记录
     * */
    lookAssessmentItem(log) {
      this.$CSDialog.alert({
        title: '通用考核项',
        messageHtml: `
            <table class="modal-inner-table" style="width: 100%;min-width: 100%;">
                <thead>
                  <tr>
                    <th>考核项</th>
                    <th>分值</th>
                  </tr>
                </thead>
                <tbody>
                    ${
                        log.items.map(item => {
                          return `<tr>
                                        <td>${item.name}</td><td>${item.score}</td>
                                    </tr>`
                        }).join('')
                    }
                 <tr>
                    <td colspan="2" class="text-right">
                        <div>
                            <span style="margin-right: 20px;">总分：${log.scoreCount}</span>
                            <span style="margin-right: 20px;">得分：${log.score}</span>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        `
      })
    },
    /**
     * 查看系统考核的任务
     * @param {Object} log 记录
     * */
    async lookSystemAssessmentTask(log) {
      let dialogObj = {};
      if (log.type === 1) {
        dialogObj.title = '相关巡检';
        const result = await this.queryInspectionTaskInfo(log.referId);
        dialogObj.messageHtml = `
          <div class="comment-panel">
             <div class="item">
                <div class="item-label">巡检计划</div>
                <div>${result.planName}</div>
             </div>
             <div class="item">
                <div class="item-label">巡检人</div>
                <div>${result.performerName}</div>
             </div>
             <div class="item">
                <div class="item-label">巡检时间</div>
                <div>${result.startTime}-${result.endTime}</div>
             </div>
             <div class="item">
                <div class="item-label">巡检点</div>
                <div>${result.pointCount}个</div>
             </div>
          </div>
        `
      } else {
        dialogObj.title = '相关工单';
        const result = await this.queryTicketInfo(log.referId),
            wordTypeMapping = {
              1: " 公共区域清洁",
              2: " 公共区域报修",
              3: " 物品进出",
              4: " 其它",
              5: " 投诉",
            };
        dialogObj.messageHtml = `
          <div class="comment-panel">
             <div class="item">
                <div class="item-label">类型</div>
                <div>${wordTypeMapping[result.type]}</div>
             </div>
             <div class="item">
                <div class="item-label">姓名</div>
                <div>${result.informantName}</div>
             </div>
             <div class="item">
                <div class="item-label">手机</div>
                <div>${result.informantPhone}</div>
             </div>
             <div class="item">
                <div class="item-label">内容</div>
                <div>${result.content}</div>
             </div>
          </div>
        `
      }
      this.$CSDialog.alert({
        ...dialogObj
      })
    },
    // 查询工单详情
    queryTicketInfo(ticketId) {
      return this.$fly.post(queryTicketUrl, {
        ticketId,
        regionId: this.$vc.getCurrentRegion().code,
      })
      .then(res => {
        if (res.code !== 0) {
          return ;
        }
        return res.data;
      })
    },
    // 查询巡检任务详情
    queryInspectionTaskInfo(taskId) {
      return this.$fly.post(queryInspectionUrl, {
        taskId
      })
          .then(res => {
            if (res.code !== 0) {
              return ;
            }
            return res.data;
          })
    },
    /**
     * 修改选中的tab
     * @param {Number} index 点击的tab的下标
     * @param {String | Array} dutyTypes 查询的部门类型
     * */
    changeTabBar(index, dutyTypes = '') {
      this.checkedTab = +index;
      // this.queryAssessmentItemsLog(dutyTypes);
    },
    /**
     * 查询品质管理考核项记录
     * @param {String | Array} dutyTypes 要查询的部门类型
     * */
    queryAssessmentItemsLog(dutyTypes = '') {
      // console.log(444,this.detailsIndex);
      let detalId=this.reportDetail.details.find((item,index)=>{
        if(this.detailsIndex==index){
          return item
        }
      })
      // console.log(777,detalId.id);
      // console.log(777,this.reportDetail)
      this.$fly.post(adminQueryItemLogUrl, {

        regionCode: this.$vc.getCurrentRegion().code,
        dutyTypes: typeof dutyTypes === 'string' ? dutyTypes : JSON.stringify(dutyTypes),
        // dutyTypes:dutyTypes,
        type: this.checkedTab,
        startDate: this.reportDetail.startTime,
        endDate: this.reportDetail.endTime,
        detailId:detalId.id
      })
      .then(res => {
        if (res.code !== 0) {
          return ;
        }
        this.assessmentItemLogData = res.data;
      })
      .catch(() => {
        this.assessmentItemLogData = [];
      })
    },
    /**
     * 保存品质报告
     * @param {Number} isSubmit 1：提交   0：保存
     * */
    saveReport(isSubmit) {
      const details = Object.values(this.editDepartmentReport);
      if (isSubmit && !details.every(item => item.id && item.isRectify !== undefined && item.statement !== undefined && item.statement !== '')) {
        this.$vc.message('请完善内容后再提交');
        return ;
      }
      this.$fly.post(editorQualityReportUrl, {
        id: this.id,
        details,
        isSubmit,
      }, {
        headers: {
          isForm: true,
        }
      })
      .then(res => {
        if (res.code !== 0) {
          return ;
        }
        if (isSubmit) {
          this.$vc.toast('提交成功');
          this.$router.back();
        } else {
          this.$vc.toast('保存成功，可前往品质检查报告查看');
        }
      })
    },
    /**
     * 修改展开状态
     * @param {Object} department 部门信息
     * @param {Number} index  点开的下标
     * */
    changeSpreadState(department, index) {
      this.detailsIndex=index
      const spreadState = this.spreadState[department.id];
      if (spreadState === false || spreadState === undefined) {
        this.changeTabBar(1, [department.dutyType]);
        if (this.type === 1) {
          this.$vc.emit(this.$route.path, `editor_${department.id}_${index}`, 'setWangEditorContent',
            this.editDepartmentReport[`${department.id}_${index}`].statement ||
            `
                <p>通用考核：</p>
                <p>业务考核：</p>
                <p>系统自动考核：</p>
            `
          );
        }
      }
      this.$set(this.spreadState, department.id, !spreadState);
    },
    // 获取品质报告详情
    getReportDetail() {
      return this.$fly.post(adminQueryReportDetailUrl, {
        regionCode: this.$vc.getCurrentRegion().code,
        id: this.id,
      })
      .then(res => {
        if (res.code !== 0) {
          return ;
        }
        console.log(123,res.data.details);
        res.data.details.forEach((val, index)=> {
          this.$set(this.editDepartmentReport, `${val.id}_${index}`, {
            statement: val.statement,
            isRectify: val.isRectify === undefined ? '' : val.isRectify,
            id: val.id,
          })
          this.$vc.on(this.$route.path, `qualityReportDetail${val.id}_editor_${index}`, 'changeEditor', html => {
            this.$set(this.editDepartmentReport[`${val.id}_${index}`], 'statement', html);
          })
        })
        this.reportDetail = res.data;
        return res.data;
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.report-info
  background #fff
  box-shadow 0 5px 10px 0 rgba(0, 0, 0, 0.1)
  padding 30px
  color #000
  margin-bottom 20px
  &-item
    font-size 24px
    &:not(:last-of-type)
      margin-bottom 30px
    span
      display inline-block
      vertical-align middle
    &-label
      width 98px
      text-align right
      margin-right 40px
.reverse
  transform rotateX(180deg)
.department-report
  padding 20px 30px
  background #fff
  box-shadow 0 5px 10px 0 rgba(0, 0, 0, 0.1)
  font-size 24px
  color #000
  &:not(:last-child)
    margin-bottom 20px
  & > span
    display inline-block
    vertical-align middle
    &:not(:last-of-type)
      margin-right 100px
  & > div
    display inline-block
    vertical-align middle
    float right
    .icon
      margin-left 10px
  &-detail
    background #fff
    font-size 0
    color #000
    padding 20px 30px
    box-shadow 0 5px 10px 0 rgba(0, 0, 0, 0.1)
    &:not(:last-child)
      margin-bottom 20px
  &-item
    &.divider
      position relative
      padding-bottom 20px
      &::before
        position absolute
        bottom 0
        left 0
        width 100%
        height 1px
        background #F0F0F0
        content " "
    &:not(:last-of-type)
      margin-bottom 30px
    & > div
      font-size 24px
      display inline-block
      vertical-align middle
    &-label
      width 100px
      margin-right 40px
      &.top
        margin-top -5px
        vertical-align top
    &-content
      width calc(100% - 140px)
      /deep/.tab-bar
        div
          width 200px
          height 50px
          line-height 50px
          font-size 24px
.btn-group-wrap
  margin 50px auto
  text-align center
  .btn
    width 80px
    font-size 24px
    padding 0
    height 40px
    &:not(:last-child)
      margin-right 50px
</style>
